@import url(https://fonts.googleapis.com/css2?family=Quintessential&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.descripcion{
    color: #636262;
    text-align: center;
    font-size: 18px;
    padding: 15px 0 15px 0;
}

.carousel-all{
    padding-top: 200px;
    display: block;
}

.tab1{
    width: 23vw;
    margin: -15px;
    /* background-image: url("../../assets/tabbg.png"); */
    background-size: cover;
}

.logourdigital{
    padding: 20px;
}

.icon{
    padding-left: 10px;
    /* padding: 10% 0% 10% 30%; */
}

.tabs-completo{
    background-color:darkmagenta;
}

.logour{
    padding: 20px;
}

.avaliable{
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.tittleText{
  height: "40vh";
  text-align: "right";
  font-family: 'Saira Stencil One';
}

.miniatura{
  /* max-height: 100px; */
  /* border:none; */
  background-color:rgb(255, 255, 255);
  margin: 10px;
  border-color: rgb(220, 228, 255);
  border-radius: 15px;
}

.miniatura:hover{
  background-color: rgb(255, 255, 255);
  border-color: rgb(164, 185, 255);
  /* border:none; */
}

.miniatura:focus{
  background-color: rgb(255, 255, 255);
  border-color: rgb(158, 53, 135);
  /* border:none; */
}

.caja-imagenes{
  height: 50vh;
  width: 100%;
  overflow: auto;
  /* overflow-y: scroll;
  overflow-x: hidden; */
}

.caja-imagenes::-webkit-scrollbar {
  width: 8px;     /* Tamaño del scroll en vertical */
  height: 8px;    /* Tamaño del scroll en horizontal */
}

.caja-imagenes::-webkit-scrollbar-track {
  background: #ffffff;
}

.caja-imagenes::-webkit-scrollbar-thumb {
  background: #7a86be;
  border-radius: 10px;
}

.caja-imagenes::-webkit-scrollbar-thumb:hover {
  background: #303c72;
}

.video-container{
  height: 40vh;
}
.texto-historia{
    color:#5c5a5a;
    line-height: 2;
    padding: 70px 30px 0px 30px;
    font-family: 'Quintessential';
    font-size: 18px;
}

.caja-texto-historia{
    box-shadow: 2px 2px 12px 0px rgba(50, 50, 50, 0.76);
    border-radius: 10px;
    /* margin:20px; */
    /* box-shadow: 2px 2px 12px 0px rgba(50, 50, 50, 0.76); */
    /* padding: 20px; */
}


.texto-historia{
    color:#5c5a5a;
    line-height: 2;
    padding: 70px 30px 0px 30px;
}

.root-historia{
    /* background-color: rgb(241, 236, 190); */
    margin: 0px;
    padding: 0%;
    /* background-image: url("../../../../assets/Papiro_version2.png"); */
    /* background-size: cover; */
    /* background-repeat: no-repeat; */
    /* background-attachment: fixed; */
}
.creditos1{
    padding-left: 40px;
}

.acerca-div{
    padding-top: 50px;
}

.titulo-acerca{
    color:#4274ad;
}
