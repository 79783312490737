.texto-historia{
    color:#5c5a5a;
    line-height: 2;
    padding: 70px 30px 0px 30px;
}

.root-historia{
    /* background-color: rgb(241, 236, 190); */
    margin: 0px;
    padding: 0%;
    /* background-image: url("../../../../assets/Papiro_version2.png"); */
    /* background-size: cover; */
    /* background-repeat: no-repeat; */
    /* background-attachment: fixed; */
}