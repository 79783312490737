.creditos1{
    padding-left: 40px;
}

.acerca-div{
    padding-top: 50px;
}

.titulo-acerca{
    color:#4274ad;
}