.descripcion{
    color: #636262;
    text-align: center;
    font-size: 18px;
    padding: 15px 0 15px 0;
}

.carousel-all{
    padding-top: 200px;
    display: block;
}

.tab1{
    width: 23vw;
    margin: -15px;
    /* background-image: url("../../assets/tabbg.png"); */
    background-size: cover;
}

.logourdigital{
    padding: 20px;
}

.icon{
    padding-left: 10px;
    /* padding: 10% 0% 10% 30%; */
}

.tabs-completo{
    background-color:darkmagenta;
}

.logour{
    padding: 20px;
}

.avaliable{
    margin-left: auto;
    margin-right: auto;
    display: block;
}