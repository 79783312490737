.tittleText{
  height: "40vh";
  text-align: "right";
  font-family: 'Saira Stencil One';
}

.miniatura{
  /* max-height: 100px; */
  /* border:none; */
  background-color:rgb(255, 255, 255);
  margin: 10px;
  border-color: rgb(220, 228, 255);
  border-radius: 15px;
}

.miniatura:hover{
  background-color: rgb(255, 255, 255);
  border-color: rgb(164, 185, 255);
  /* border:none; */
}

.miniatura:focus{
  background-color: rgb(255, 255, 255);
  border-color: rgb(158, 53, 135);
  /* border:none; */
}

.caja-imagenes{
  height: 50vh;
  width: 100%;
  overflow: auto;
  /* overflow-y: scroll;
  overflow-x: hidden; */
}

.caja-imagenes::-webkit-scrollbar {
  width: 8px;     /* Tamaño del scroll en vertical */
  height: 8px;    /* Tamaño del scroll en horizontal */
}

.caja-imagenes::-webkit-scrollbar-track {
  background: #ffffff;
}

.caja-imagenes::-webkit-scrollbar-thumb {
  background: #7a86be;
  border-radius: 10px;
}

.caja-imagenes::-webkit-scrollbar-thumb:hover {
  background: #303c72;
}

.video-container{
  height: 40vh;
}