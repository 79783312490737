@import url('https://fonts.googleapis.com/css2?family=Quintessential&display=swap');

.texto-historia{
    color:#5c5a5a;
    line-height: 2;
    padding: 70px 30px 0px 30px;
    font-family: 'Quintessential';
    font-size: 18px;
}

.caja-texto-historia{
    box-shadow: 2px 2px 12px 0px rgba(50, 50, 50, 0.76);
    border-radius: 10px;
    /* margin:20px; */
    /* box-shadow: 2px 2px 12px 0px rgba(50, 50, 50, 0.76); */
    /* padding: 20px; */
}

